import { TypeAccordion } from '../../types/contentful'
import AccordionBlock from './AccordionBlock'
import isDarkColor from '../../helpers/isDarkColor'
import { CSSProperties, useCallback, useRef } from 'react'
import classnames from 'classnames'
import { Icon } from '../Icon/Icon'
import TitleBlock from '../TitleBlock/TitleBlock'
import useAccordionAnimation from '../../helpers/hooks/useAccordionAnimation'
import { CSSTransition } from 'react-transition-group'

type NestedAccordionBlockProps = {
  block: TypeAccordion<'WITHOUT_UNRESOLVABLE_LINKS', string>
  position?: 'top' | 'middle' | 'bottom'
}

const SPEED = 150

const NestedAccordionBlock = ({ block, position }: NestedAccordionBlockProps) => {
  const { preTitle, title, showTitle, backgroundColour, color, heading } = block.fields
  const { open, toggleAnimation } = useAccordionAnimation(false, SPEED)
  const transitionRef = useRef<HTMLDivElement>(null)

  const top = position === 'top',
    middle = position === 'middle',
    bottom = position === 'bottom'

  const accordionColorCSSVars = {
    '--accordion-color': color,
  } as CSSProperties

  const handleClick = useCallback(() => {
    toggleAnimation()
  }, [toggleAnimation])

  const containerClass = classnames('container mx-auto max-w-4xl', {
    'pt-3 md:pt-6 lg:pt-6': top,
    'pb-3 md:pb-6 lg:pb-6': bottom,
    'py-3 md:py-6 lg:py-6': !top && !middle && !bottom,
  })

  const accordionSectionClass = classnames('px-10 lg:bg-[--accordion-color]', {
    'pt-6 pb-3': top,
    'py-3': middle,
    'pt-3 pb-6': bottom,
  })

  return (
    <div
      className={classnames(
        'full-width',
        backgroundColour && isDarkColor(backgroundColour) && 'text-white dark',
        top && 'pt-10',
        bottom && 'pb-10'
      )}
      style={{ backgroundColor: backgroundColour }}
    >
      {showTitle === true && position === 'top' && (
        <TitleBlock title={title} preTitle={preTitle} headingType={`h2`} size={`5xl`} />
      )}
      <div className={containerClass}>
        <div className={accordionSectionClass} style={accordionColorCSSVars}>
          <div className={`m-0 border-b-2 border-accent-4-200 pb-5`}>
            <button
              id={block?.sys?.id}
              onClick={handleClick}
              className={'relative block w-full text-left font-medium'}
            >
              <h2 className={'w-[95%] font-primary font-bold m-0 text-2xl'}>{heading}</h2>
              <Icon
                name={open ? 'ToggleUp' : 'ToggleDown'}
                className={'absolute right-0 top-1 text-accent-4-800 dark:text-primary-ui-white-50'}
              />
            </button>
            <CSSTransition
              in={open}
              timeout={SPEED}
              classNames="accordion"
              unmountOnExit
              nodeRef={transitionRef}
            >
              {block && (
                <div ref={transitionRef}>
                  <AccordionBlock block={block} position={position} isNested />
                </div>
              )}
            </CSSTransition>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NestedAccordionBlock
