import {
  createContentfulClient,
  getFooter,
  getGraphqlResources,
  getMenu,
  getPageBySlug,
} from '../../lib/contentful'
import { GetStaticPropsResult } from 'next/types'
import { useLocalisedContent } from '../../context/Location/useLocalisedContent'
import { GraphqlResources } from '../../types/content'
import ContentfulDocument from '../ContentfulDocument/ContentfulDocument'
import Footer from '../Footer/Footer'
import Menu from '../Menu/Menu'
import { TypePage, TypeResourceSet } from '../../types/contentful'
import { createApolloServerClient } from '../../lib/apolloServerClient'
import TitleBlock from '../TitleBlock/TitleBlock'
import Button, { contentfulSize, contentfulType, contentfulVariant } from '../Button/Button'
import { Icon } from '../Icon/Icon'
import React, { useEffect } from 'react'
import HeadBlock from '../SeoBlock/HeadBlock'
import { revalidate } from '../../lib/cache'
import { isAcademyExAu } from '../../lib/detectEnvironment'
import Sections from '../Sections/Sections'
import ContentfulResourceContext from '../ContentfulDocument/ContentfulResourcesProvider'
import HeroBlock from '../HeroBlock/HeroBlock'
import classNames from 'classnames'
import InfinitySliderBlock from '../InfinitySliderBlock/InfinitySliderBlock'
import ContentModalBlock from '../ModalBlock/ContentModalBlock'
import { clearProducts } from '../../modules/ecommerce/product-data-layer'

export type ContentPageProps = {
  page: TypePage<'WITHOUT_UNRESOLVABLE_LINKS', string>
  menu: TypeResourceSet<'WITHOUT_UNRESOLVABLE_LINKS', string>
  footer: TypeResourceSet<'WITHOUT_UNRESOLVABLE_LINKS', string>
  graphqlResources: GraphqlResources
  version?: string
  slug?: string
  isCardsPage?: boolean
}

export const ContentPage = ({
  page,
  menu,
  footer,
  graphqlResources,
  slug,
  isCardsPage,
}: ContentPageProps) => {
  const localizedPage = useLocalisedContent(page)

  useEffect(() => {
    clearProducts()
  }, [])

  return (
    <>
      <HeadBlock
        title={localizedPage?.fields?.seoTitle || localizedPage?.fields?.title}
        description={localizedPage?.fields?.seoDescription}
        keywords={localizedPage?.fields?.seoKeywords}
        image={localizedPage?.fields?.seoImage?.fields?.image?.fields?.file?.url}
        noIndex={localizedPage?.fields?.seoNoIndex}
      />
      <Menu menu={menu} theme={localizedPage?.fields?.navigationTheme} />
      {localizedPage?.fields?.showPageTitle === true && (
        <TitleBlock
          title={localizedPage?.fields?.title}
          preTitle={localizedPage?.fields?.preTitle}
          isHero={true}
        >
          <div className={'flex justify-center md:gap-4 flex-wrap md:flex-nowrap'}>
            {localizedPage?.fields?.ctaButtons?.map(button => (
              <Button
                id={button.sys.id}
                key={button.sys.id}
                href={button.fields.url}
                buttonStyle={contentfulType[button.fields.style]}
                size={contentfulSize[button.fields.size]}
                urlBehaviour={button?.fields?.urlBehaviour}
                className={
                  'flex w-[70%] md:w-auto my-2 md:my-0 text-center justify-center items-center'
                }
                variant={contentfulVariant[button?.fields?.variant]}
                isVideoVariantTrackable={button?.fields?.isVideoVariantTrackable}
                showOnMobileOnly={button?.fields?.showOnMobileOnly}
                mobileWidth={button?.fields?.mobileWidth}
              >
                {button.fields.icon && <Icon className="mr-1" name={button.fields.icon} />}
                {button.fields.label}
              </Button>
            ))}
          </div>
        </TitleBlock>
      )}
      <main
        className={classNames(
          localizedPage?.fields?.marginType === 'Center'
            ? 'w-[85%] sm:w-[90%] mx-auto md:px-[20%]'
            : 'container mx-auto',
          isCardsPage &&
            '[&>*>div#image]:w-1/2 [&>*>ol>li>div#image]:w-1/2 [&>*>ul>li>div#image]:w-1/2'
        )}
      >
        {slug === 'ch-home' && <InfinitySliderBlock />}

        {localizedPage?.fields?.heroBlock && <HeroBlock entry={localizedPage?.fields?.heroBlock} />}

        {localizedPage?.fields?.sections && (
          <section className="container mx-auto">
            <ContentfulResourceContext.Provider value={graphqlResources}>
              <Sections
                sections={localizedPage.fields.sections}
                navigationTheme={localizedPage?.fields?.navigationTheme}
                navbarMargin={localizedPage?.fields?.navbarMargin}
              />
            </ContentfulResourceContext.Provider>
          </section>
        )}

        <ContentfulDocument
          content={localizedPage?.fields?.content}
          resources={graphqlResources}
          menu={menu}
        />
      </main>
      <Footer menu={footer} colour={localizedPage?.fields?.footerColour} />
    </>
  )
}

export const generateSSRContentPageProps = async (
  slug: string,
  version?: string
): Promise<GetStaticPropsResult<ContentPageProps>> => {
  const contentfulClient = createContentfulClient()
  const apollo = await createApolloServerClient()
  const isSubdomain = isAcademyExAu()

  const [page, menu, footer, graphqlResources] = await Promise.all([
    getPageBySlug(contentfulClient, slug, isSubdomain),
    getMenu(contentfulClient, isSubdomain),
    getFooter(contentfulClient, isSubdomain),
    getGraphqlResources(apollo),
  ])

  if (!page) {
    return {
      notFound: true,
      revalidate,
    }
  }

  return {
    props: {
      page,
      menu,
      footer,
      graphqlResources,
      ...(version && { version }), // Add version only if not null
      slug,
    },
  }
}

export const generateStaticContentPageProps = async (
  slug: string,
  version?: string
): Promise<GetStaticPropsResult<ContentPageProps>> => {
  const contentfulClient = createContentfulClient()
  const apollo = await createApolloServerClient()
  const isSubdomain = isAcademyExAu()
  const [page, menu, footer, graphqlResources] = await Promise.all([
    getPageBySlug(contentfulClient, slug, isSubdomain),
    getMenu(contentfulClient, isSubdomain),
    getFooter(contentfulClient, isSubdomain),
    getGraphqlResources(apollo),
  ])

  if (!page) {
    return {
      notFound: true,
      revalidate,
    }
  }

  return {
    props: {
      page,
      menu,
      footer,
      graphqlResources,
      ...(version && { version }), // Add version only if not null
      slug,
      ...(page?.fields?.slug?.['en-NZ']?.includes('/cards') && { isCardsPage: true }),
    },
    revalidate,
  }
}

export default ContentPage
