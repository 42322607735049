import React, { useEffect, useState } from 'react'
import { TypeSection } from '../../types/contentful'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'
import slugify from 'slugify'
import { animateScroll as scroll, scroller } from 'react-scroll'
import { useInView } from 'react-intersection-observer'
import { useDebouncedCallback } from 'use-debounce'
import CardHighlightContentBlockV2 from '../CardHighlightContentBlock/CardHighlightContentBlockV2'
import classNames from 'classnames'
import { isContentfulType } from '../../lib/contentful'
import AccordionBlock from '../AccordionBlock/AccordionBlock'
import NestedAccordionBlock from '../AccordionBlock/NestedAccordionBlock'
import TopLevelBlock from '@contentful/rich-text-types/dist'

type SectionsProps = {
  sections: TypeSection<'WITHOUT_UNRESOLVABLE_LINKS', string>[]
  navigationTheme?: string
  CTACardCourse?: JSX.Element
  isCourseV2?: boolean
  showTopBanner?: boolean
  navbarMargin?: boolean
}

const SPEED = 5

const renderCourseV2CardHighlightAndAccordionBlocks = (
  isCardHighlightContentForCourse: boolean,
  accordionBlocks: TopLevelBlock[],
  section: TypeSection<'WITHOUT_UNRESOLVABLE_LINKS', string>,
  CTACardCourse: JSX.Element,
  isCourseV2: boolean
) => {
  const cardHighlight = isCardHighlightContentForCourse && (
    <CardHighlightContentBlockV2
      entry={section?.fields?.content?.content?.[0]?.data?.target}
      CTACardCourse={CTACardCourse}
      isCourseV2={isCourseV2}
    />
  )

  const accordionBlocksRendered =
    accordionBlocks.length > 1
      ? accordionBlocks?.map((block, i) => (
          <NestedAccordionBlock
            block={block?.data?.target}
            key={i}
            position={
              accordionBlocks?.length > 1
                ? i === 0
                  ? 'top'
                  : i === accordionBlocks?.length - 1
                  ? 'bottom'
                  : 'middle'
                : undefined
            }
          />
        ))
      : !!accordionBlocks.length && <AccordionBlock block={accordionBlocks[0]?.data?.target} />

  const documentComponents = renderDocumentToReactComponents({
    ...section?.fields?.content,
    content: isCardHighlightContentForCourse
      ? section?.fields?.content?.content?.slice(1)
      : section?.fields?.content?.content?.filter(
          topLevelBlock => !isContentfulType(topLevelBlock?.data?.target, 'accordion')
        ),
  })

  return (
    <>
      {cardHighlight}
      {accordionBlocksRendered}
      {documentComponents}
    </>
  )
}

const Sections = ({
  sections,
  navigationTheme,
  isCourseV2,
  CTACardCourse,
  showTopBanner,
  navbarMargin,
}: SectionsProps) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })
  // const router = useRouter()
  const [isSticky, setIsSticky] = useState(false)
  const [sectionIndex, setSectionIndex] = useState(0)

  // Note: Some sections can be deleted
  const validSections = sections?.filter(section => !!section.fields)

  const navbarSections = validSections.filter(section => !section.fields.hideFromNavbar)

  const handleScroll = useDebouncedCallback(() => {
    // find current scroll position
    const currentScrollPos = window.scrollY

    const breakpoint = navbarMargin ? 40 : 400

    // set state based on location
    setIsSticky(currentScrollPos > breakpoint && !inView)
  }, SPEED)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  useEffect(() => {
    const sectionIds = navbarSections.map(section => slugify(section.fields.title))

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const sectionId = entry.target.id
            setSectionIndex(sectionIds.indexOf(sectionId) || 0)
          }
        })
      },
      { rootMargin: '-50% 0px -50% 0px' }
    )

    sectionIds.forEach(sectionId => {
      const section = document.getElementById(sectionId)
      if (section) {
        observer.observe(section)
      }
    })

    return () => {
      observer.disconnect()
    }
  }, [])

  const [topBannerHeight, setTopBannerHeight] = useState(0)

  useEffect(() => {
    setTopBannerHeight(document?.getElementById('top-banner')?.clientHeight || 0)
  }, [])

  const renderSections = (navbarMargin: boolean) => {
    return (
      <div className="flex uppercase sm:container mx-auto">
        {navbarSections?.map((section, index) => {
          const selected = index === sectionIndex
          const slug = slugify(section.fields.title)
          return (
            <div
              className={classNames('grow text-center', { 'mt-navbar': navbarMargin })}
              key={section.sys.id}
              id={`tab_${slug}`}
            >
              <a
                onClick={e => {
                  e.preventDefault()
                  setSectionIndex(index)
                  scroller.scrollTo(slug, {
                    offset: -200,
                    duration: 300,
                    smooth: true,
                  })
                  // router.push(`#${slug}`, undefined, { shallow: true })
                }}
                className={classNames(
                  'cursor-pointer block whitespace-nowrap pb-4 px-4 border-black border-b-[6px] font-medium text-sm outline-none',
                  !selected &&
                    'border-transparent text-secondary-ui-500 hover:text-secondary-ui-700 hover:border-primary-ui-black',
                  isSticky ? 'pt-4' : 'pt-12',
                  isSticky &&
                    navigationTheme === 'Dark Navigation' && [
                      selected ? 'text-white border-b-white' : 'text-white-80',
                      selected ? 'border-transparent' : '',
                      'hover:text-white hover:border-b-white',
                    ]
                )}
              >
                <span className="font-bold text-sm">{section.fields.title}</span>
              </a>
            </div>
          )
        })}
        {isSticky && (
          <div
            className={`border-transparent block whitespace-nowrap py-4 px-4 border-b-[6px] font-medium text-sm outline-none cursor-pointer ${
              navigationTheme === 'Dark Navigation'
                ? 'bg-[#000000] text-white-80 hover:text-white hover:border-b-white'
                : 'text-secondary-ui-500 hover:text-secondary-ui-700 hover:border-primary-ui-black'
            }`}
            onClick={() => {
              {
                scroll.scrollToTop()
                // router.push('#', undefined, { shallow: true })
              }
            }}
          >
            Go back to top
          </div>
        )}
      </div>
    )
  }

  if (!validSections?.length) {
    return null
  }

  return (
    <>
      <div className="relative">
        <div ref={ref} />
        <div
          className={`hidden md:block -mb-px -mx-4 md:-mx-8 bg-primary-ui-white overflow-x-auto`}
        >
          {renderSections(navbarMargin)}
        </div>
        {(inView || isSticky) && (
          <div
            className={classNames(
              'hidden md:block top-20 mx-0 fixed -mb-px md:-mx-8 left-0 right-0 overflow-x-auto z-[200] duration-300 transition-opacity shadow-lg',
              inView ? '!hidden' : '',
              isSticky ? 'opacity-100' : 'opacity-0',
              navigationTheme === 'Dark Navigation' ? 'bg-[#000000]' : 'bg-primary-ui-white'
            )}
            style={showTopBanner ? { marginTop: topBannerHeight } : undefined}
          >
            {renderSections(false)}
          </div>
        )}
        {/* Create a gap between the section navigation and content */}
        {validSections?.map((section, index) => {
          const slug = slugify(section.fields.title)
          const isCardHighlightContentForCourse =
            index === 0 &&
            isContentfulType(
              section?.fields?.content?.content?.[0]?.data?.target,
              'cardHighlightContentBlock'
            ) &&
            isCourseV2
          const accordionBlocks =
            isCourseV2 &&
            section?.fields?.content?.content?.filter(block => {
              return isContentfulType(block?.data?.target, 'accordion')
            })

          return (
            <section key={section.sys.id} id={slug}>
              {isCardHighlightContentForCourse || accordionBlocks?.length
                ? renderCourseV2CardHighlightAndAccordionBlocks(
                    isCardHighlightContentForCourse,
                    accordionBlocks,
                    section,
                    CTACardCourse,
                    isCourseV2
                  )
                : renderDocumentToReactComponents(section.fields.content)}
            </section>
          )
        })}
      </div>
    </>
  )
}

export default Sections
