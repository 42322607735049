import { GraphqlResources } from '../../types/content'
import { Document } from '@contentful/rich-text-types'
import renderDocumentToReactComponents from '../../modules/contentful/renderDocumentToReactComponents/renderDocumentToReactComponents'
import ContentfulResourceContext from './ContentfulResourcesProvider'
import { isContentfulType } from '../../lib/contentful'
import { TypeModalBlock, TypeResourceSet } from '../../types/contentful'
import ContentModalBlock from '../ModalBlock/ContentModalBlock'

type ContentfulDocumentArgs = {
  content: Document
  resources: GraphqlResources
  menu?: TypeResourceSet<'WITHOUT_UNRESOLVABLE_LINKS', string>
}

const ContentfulDocument = ({ content, resources, menu }: ContentfulDocumentArgs) => {
  return (
    <ContentfulResourceContext.Provider value={{ ...resources, useSection: true }}>
      {isContentfulType<TypeModalBlock<'WITHOUT_UNRESOLVABLE_LINKS', string>>(
        content?.content?.[0]?.data?.target,
        'modalBlock'
      ) &&
        content?.content?.[0]?.data?.target?.fields?.variant === 'Splash Page' && (
          <ContentModalBlock menu={menu} entry={content?.content?.[0]?.data.target} />
        )}

      <>{renderDocumentToReactComponents(content)}</>
    </ContentfulResourceContext.Provider>
  )
}

export default ContentfulDocument
