import React from 'react'
import Image from 'next/legacy/image'
import getFocalRatio from '../../helpers/getFocalRatio'
import { getShapeClass } from '../Shapes/Shapes'
import classnames from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'

// Import Swiper styles
import 'swiper/css'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Slides = any

const slides: Slides = [
  {
    title: 'Slide 1',
    image: {
      fields: {
        focalPoint: {
          x: 0.5,
          y: 0.5,
        },
        title: 'Image 1',
        image: {
          fields: {
            file: {
              url: '/images/a0af29a5df3be5e970aec99e5f163ea4.jpeg',
              fileName: '2c4f0e385',
              contentType: 'image/jpeg',
              details: {
                size: 123453,
                image: {
                  width: 500,
                  height: 500,
                },
              },
            },
          },
        },
      },
    },
    shape: 'Bubble',
    size: 'Medium Tall',
  },
  {
    title: 'Slide 1',
    image: {
      fields: {
        focalPoint: {
          x: 250,
          y: 250,
        },
        title: 'Image 1',
        image: {
          fields: {
            file: {
              url: '/images/3926099f1e12a66c5030e6223d9ab3d8.jpg',
              fileName: '2c4f0e385',
              contentType: 'image/jpeg',
              details: {
                size: 123453,
                image: {
                  width: 500,
                  height: 500,
                },
              },
            },
          },
        },
      },
    },
    shape: 'Bolt',
    size: 'Small Square',
  },
  {
    title: 'Slide 1',
    image: {
      fields: {
        focalPoint: {
          x: 250,
          y: 250,
        },
        title: 'Image 1',
        image: {
          fields: {
            file: {
              url: '/images/e411f5a7c56e4d1913cd1f83e254f0d4.jpeg',
              fileName: '2c4f0e385',
              contentType: 'image/png',
              details: {
                size: 123453,
                image: {
                  width: 500,
                  height: 500,
                },
              },
            },
          },
        },
      },
    },
    shape: 'Bolt',
    size: 'Medium Wide',
  },
  {
    title: 'Slide 1',
    image: {
      fields: {
        focalPoint: {
          x: 250,
          y: 250,
        },
        title: 'Image 1',
        image: {
          fields: {
            file: {
              url: '/images/2c4f0e385da7ee409505603a95c92611.jpg',
              fileName: '2c4f0e385',
              contentType: 'image/jpeg',
              details: {
                size: 123453,
                image: {
                  width: 500,
                  height: 500,
                },
              },
            },
          },
        },
      },
    },
    shape: 'Cube',
    size: 'Medium Wide',
  },
  {
    title: 'Slide 1',
    image: {
      fields: {
        focalPoint: {
          x: 250,
          y: 250,
        },
        title: 'Image 1',
        image: {
          fields: {
            file: {
              url: '/images/homepage-image-2.jpeg',
              fileName: '2c4f0e385',
              contentType: 'image/png',
              details: {
                size: 123453,
                image: {
                  width: 500,
                  height: 500,
                },
              },
            },
          },
        },
      },
    },
    shape: 'Bubble',
    size: 'Small Square',
  },
  {
    title: 'Slide 1',
    image: {
      fields: {
        focalPoint: {
          x: 250,
          y: 250,
        },
        title: 'Image 1',
        image: {
          fields: {
            file: {
              url: '/images/230d78bba3277c225034eccb6fcff3b4.jpeg',
              fileName: '2c4f0e385',
              contentType: 'image/png',
              details: {
                size: 123453,
                image: {
                  width: 500,
                  height: 500,
                },
              },
            },
          },
        },
      },
    },
    shape: 'Bolt',
    size: 'Medium Tall',
  },
  {
    title: 'Slide 1',
    image: {
      fields: {
        focalPoint: {
          x: 250,
          y: 250,
        },
        title: 'Image 1',
        image: {
          fields: {
            file: {
              url: '/images/homepage-image-1.jpeg',
              fileName: '2c4f0e385',
              contentType: 'image/png',
              details: {
                size: 123453,
                image: {
                  width: 500,
                  height: 500,
                },
              },
            },
          },
        },
      },
    },
    shape: 'Bubble',
    size: 'Small Square',
  },
  {
    title: 'Slide 1',
    image: {
      fields: {
        focalPoint: {
          x: 250,
          y: 250,
        },
        title: 'Image 1',
        image: {
          fields: {
            file: {
              url: '/images/5a06342d819bea974d064c2d9e7e2e7d.jpg',
              fileName: '2c4f0e385',
              contentType: 'image/png',
              details: {
                size: 123453,
                image: {
                  width: 500,
                  height: 500,
                },
              },
            },
          },
        },
      },
    },
    shape: 'Cube',
    size: 'Small Square',
  },
]

const InfinitySliderBlock = () => {
  return (
    <section className="relative full-width overflow-hidden">
      <div
        className={
          'absolute bg-gradient-to-r from-primary-ui-white to-transparent w-48 h-full z-10'
        }
      />
      <div
        className={
          'absolute bg-gradient-to-r from-transparent to-primary-ui-white w-48 h-full z-10 right-0'
        }
      />
      <div className={'relative infinity-slider-block w-[100rem]'}>
        <Swiper
          slidesPerView={1}
          loop={true}
          loopAdditionalSlides={1}
          loopedSlidesLimit={false}
          centeredSlides={true}
          autoplay={{
            delay: 1,
            disableOnInteraction: false,
          }}
          allowTouchMove={false}
          draggable={false}
          spaceBetween={20}
          preventClicks={true}
          speed={40000}
          navigation={false}
          modules={[Autoplay]}
          className="mySwiper"
          preloadImages={true}
          key={`swiper-InfinitySliderBlock`}
        >
          <SwiperSlide>
            <div className={'h-[32rem] grid grid-rows-2 grid-cols-6 gap-5 grid-flow-col-dense'}>
              {slides?.map((slide, index) => {
                const focalRatio = getFocalRatio(slide?.image?.fields)
                const cardStyle = classnames(
                  slide.size === 'Medium Wide' || slide.size === 'Large Square'
                    ? 'sm:col-span-2'
                    : ''
                )
                const imageStyle = classnames(
                  'relative block w-full',
                  getShapeClass(slide.size, slide.shape),
                  slide.size === 'Medium Tall' || slide.size === 'Large Square'
                    ? 'row-span-2'
                    : 'row-span-1'
                )
                return (
                  <div
                    key={index}
                    className={`${cardStyle} ${imageStyle} ${getShapeClass(
                      'Large Square',
                      slide.shape
                    )}`}
                  >
                    <Image
                      src={slide?.image?.fields?.image?.fields?.file?.url}
                      alt={slide?.image?.fields?.title}
                      key={index}
                      layout={'fill'}
                      objectFit={'cover'}
                      objectPosition={`${focalRatio.x}% ${focalRatio.y}%`}
                      priority={true}
                    />
                  </div>
                )
              })}
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  )
}

export default InfinitySliderBlock
